import {useMemo} from 'react'

interface LoginHeaderProps {
  authToken: string | undefined
  mfaLink: string | undefined
}

export const LoginHeader = ({authToken, mfaLink}: LoginHeaderProps) => {
  const heading = useMemo(() => {
    if (mfaLink && authToken) {
      return (
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Scan QR Code</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            <p>Scan the QR code with your preferred 2FA app.</p>
            <p>
              If you are on your mobile phone, tap <a href={mfaLink}>here</a>.
            </p>
          </div>
        </div>
      )
    } else if (!mfaLink && authToken) {
      return (
        <div className='mb-10'>
          <div
            className='ff-admm-bold text-center mb-10'
            style={{
              fontSize: '30px',
              color: 'white',
            }}
          >
            E-TICKET DISTRIBUTION WAIVER
          </div>
          <div className='px-2 text-start'>
            <p className='text-white mt-3 text-uppercase ff-admm-bold'>
              **Two-Factor Authentication**
            </p>
            <p className='text-white mt-3'>
              Your account is protected with two-factor authentication. We've sent you an email.
              Please enter the code below.
            </p>
          </div>
        </div>
      )
    } else {
      return (
        <div className='mb-10'>
          <div
            className='ff-admm-bold text-center mb-10'
            style={{
              fontSize: '30px',
              color: 'white',
            }}
          >
            E-TICKET DISTRIBUTION WAIVER
          </div>
          <div className='px-2 text-start'>
            <p className='text-white mt-3 text-uppercase ff-admm-bold'>
              **Important Login Information**
            </p>
            <p className='text-white mt-3'>
              Your ticket & waiver are linked directly to the email address you provided at the time
              of booking.
            </p>
          </div>
        </div>
      )
    }
  }, [authToken, mfaLink])

  return heading
}
